import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from "vuex";
import { RESET_PASSWORD } from "@/core/services/store/auth.module";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
export default {
  mixins: [validationMixin],
  name: "resetPassword",
  data: function data() {
    return {
      loading: false,
      form: {
        email: ""
      }
    };
  },
  validations: {
    form: {
      email: {
        required: required,
        email: email
      }
    }
  },
  methods: {
    validateState: function validateState(name) {
      var _this$$v$form$name = this.$v.form[name],
          $dirty = _this$$v$form$name.$dirty,
          $error = _this$$v$form$name.$error;
      return $dirty ? !$error : null;
    },
    resetForm: function resetForm() {
      var _this = this;

      this.form = {
        email: null,
        password: null
      };
      this.$nextTick(function () {
        _this.$v.$reset();
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;

      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      var email = this.$v.form.email.$model;
      this.loading = true; // send login request

      this.$store.dispatch(RESET_PASSWORD, {
        email: email
      }) // go to which page after successfully login
      .then(function (_ref) {
        var isSuccess = _ref.isSuccess,
            message = _ref.message;
        _this2.loading = false;

        if (isSuccess) {
          _this2.$router.push("/login");

          Swal.fire({
            title: "",
            text: message,
            icon: "success"
          });
        } else {
          Swal.fire({
            title: "",
            text: message,
            icon: "error"
          });
        }
      }).catch(function (_ref2) {
        var message = _ref2.message;
        _this2.loading = false;
        Swal.fire({
          title: "",
          text: message,
          icon: "error"
        });
      });
    }
  },
  computed: _objectSpread({}, mapState({
    errors: function errors(state) {
      return state.auth.errors;
    }
  }))
};